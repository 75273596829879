import React from 'react';
import { Link } from 'react-router-dom';
const MenuItem = ({ section, icon }) => {
    const currentSection = window.location.href.split("/")[3];
    return (React.createElement("div", { className: 'mb-2' },
        React.createElement(Link, { to: '/' + section, style: { 'textDecoration': 'none' } },
            React.createElement("div", { className: "navbar-brand p-0 d-flex align-items-center justify-content-center", href: "#", style: { 'textDecoration': 'none', 'backgroundColor': currentSection == section ? 'rgba(255, 143, 0, 0.1)' : 'white', 'position': 'relative', 'border': '1px solid #6E0E0A', 'width': '32px', 'height': '32px' } },
                React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'relative', 'fontSize': '20px', 'top': '1px', 'left': '-1px', 'textDecoration': 'none' } }, icon)))));
};
const LeftSidebar = () => {
    return (React.createElement("div", { style: { 'paddingRight': '16px' } },
        React.createElement(MenuItem, { section: 'games', icon: 'playing_cards' }),
        React.createElement(MenuItem, { section: 'people', icon: 'groups' })));
};
export { LeftSidebar };
