import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from 'client/components/Header';
import { Footer } from 'client/components/Footer';
import { Loading } from 'client/components/Loading';
import { NewGame } from 'client/pages/NewGame';
import { Leaderboard } from 'client/pages/Leaderboard';
import { GameHeader } from 'client/pages/GameHeader';
import { GamesList } from 'client/pages/GamesList';
import { HowItWorks } from 'client/pages/HowItWorks';
import axios from 'axios';
import { LeftSidebar } from 'client/components/LeftSidebar';
import { RightSidebar } from 'client/components/RightSidebar';
import { PlayGame } from 'client/pages/PlayGame';
const Game = ({ token, person }) => {
    const [players, setPlayers] = useState([]);
    const [games, setGames] = useState(null);
    const [isLoaded, setIsLoaded] = useState({
        fetchGames: false,
        articlesReady: true
    });
    const { id } = useParams();
    const [activeGame, setActiveGame] = useState(null);
    useEffect(() => {
        if (id) {
            setActiveGame(Number(id));
        }
    }, []);
    useEffect(() => {
        axios.post('/api/game/fetchPlayers', {})
            .then(response => {
            setPlayers(response.data);
        })
            .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }, []);
    useEffect(() => {
        axios.post('/api/game/fetchGames', {
            person: person.id
        })
            .then(response => {
            setGames(response.data);
            setIsLoaded(prevState => (Object.assign(Object.assign({}, prevState), { fetchGames: true })));
        })
            .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }, []);
    return (React.createElement("div", { className: 'container-fluid flex-none d-flex flex-column min-vh-100' },
        React.createElement(Header, { person: person, title: "Games" }),
        React.createElement("div", { className: 'container-fluid flex-grow-1 d-flex' },
            React.createElement(LeftSidebar, null),
            React.createElement("div", { className: 'container-fluid' },
                React.createElement("div", { className: 'row g-3' },
                    React.createElement("div", { className: 'col-lg-3' },
                        React.createElement(GameHeader, null),
                        React.createElement(NewGame, { players: players, person: person, setGames: setGames, setActiveGame: setActiveGame }),
                        React.createElement(HowItWorks, null),
                        React.createElement(Leaderboard, { players: players, games: games })),
                    React.createElement("div", { className: 'col-lg-9' },
                        React.createElement("div", { className: 'card d-block border-dark', style: { 'borderRadius': '4px', 'height': 'calc(100vh - 160px)' } }, Object.values(isLoaded).every(value => value === true)
                            ? React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'card-header d-flex justify-content-center border-dark', style: { 'padding': '1px', 'backgroundColor': 'rgba(255, 143, 0, 0.1)' } },
                                    React.createElement("h6", { className: 'card-title small', style: { 'marginBottom': '4px' } },
                                        React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'relative', 'top': '4px', 'right': '1px', 'fontSize': '18px' } }, "swords"),
                                        "Play")),
                                React.createElement("div", { className: 'd-flex', style: { 'height': 'calc(100% - 30px)' } },
                                    React.createElement("div", { className: 'border-end border-dark', style: { 'overflowY': 'scroll', 'width': '280px', 'borderBottomLeftRadius': '4px' } },
                                        React.createElement(GamesList, { games: games, activeGame: activeGame, setActiveGame: setActiveGame, person: person })),
                                    React.createElement("div", { className: 'd-flex flex-grow-1', style: { 'overflowY': 'scroll' } }, (activeGame && games) &&
                                        React.createElement(React.Fragment, null, games.map((g) => (React.createElement("div", { key: g.id, className: `w-100 d-flex ${activeGame == g.id ? '' : 'd-none'}` },
                                            React.createElement(PlayGame, { game: g, person: person, setGames: setGames, setIsLoaded: setIsLoaded }))))))))
                            : React.createElement("div", { className: 'd-flex align-items-center flex-grow-1 h-100' },
                                React.createElement(Loading, null)))))),
            React.createElement(RightSidebar, null)),
        React.createElement(Footer, null)));
};
export { Game };
