var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'client/components/Modal';
const NewGame = ({ token, person, players, games, setGames, setActiveGame }) => {
    const [showNewGameModal, setShowNewGameModal] = useState(false);
    const [passesValidation, setPassesValidation] = useState(false);
    const [isValidStartLink, setIsValidStartLink] = useState(false);
    const [isValidEndLink, setIsValidEndLink] = useState(false);
    const [startLink, setStartLink] = useState('');
    const [endLink, setEndLink] = useState('');
    const [startMessage, setStartMessage] = useState('');
    const [endMessage, setEndMessage] = useState('');
    const [player2, setPlayer2] = useState('');
    const [newGameCreated, setNewGameCreated] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const handleShowNewGameModal = () => setShowNewGameModal(true);
    const handleCloseNewGameModal = () => setShowNewGameModal(false);
    let removeOneFlag = true;
    const opponents = players.filter(item => {
        if (item.id === person.id && removeOneFlag) {
            removeOneFlag = false;
            return false;
        }
        return true;
    });
    useEffect(() => {
        if (player2 !== "" && isValidStartLink && isValidEndLink) {
            setPassesValidation(true);
        }
        else {
            setPassesValidation(false);
        }
    }, [player2, isValidStartLink, isValidEndLink]);
    const validateLink = (link, setFunction, setValidFunction, setGames) => __awaiter(void 0, void 0, void 0, function* () {
        if (!link.includes('https://en.wikipedia.org/wiki/')) {
            setFunction(React.createElement("div", { className: 'pt-2 text-danger' }, "That is not a valid wikipedia link."));
            setValidFunction(false);
            return;
        }
        try {
            //const response = await fetch(link);
            const content = React.createElement("div", { className: 'd-flex align-items-center mt-2' }, "Checking...");
            setFunction(content);
            const title = link.split('/').pop();
            const response = yield fetch('https://en.wikipedia.org/api/rest_v1/page/summary/' + title);
            const data = yield response.json();
            if (response.ok) {
                let displayTitle = data.title;
                // displayTitle = displayTitle.length > 17 ? `${displayTitle.slice(0, 17)}...` : displayTitle
                const content = React.createElement("div", { className: 'd-flex align-items-center mt-2' },
                    React.createElement("img", { src: data.originalimage.source, height: '30px', className: 'me-2 rounded' }),
                    displayTitle);
                setFunction(content);
                setValidFunction(true);
            }
            else {
                setFunction(React.createElement("div", { className: 'pt-2 text-danger' }, "That is not a valid wikipedia link."));
                setValidFunction(false);
            }
        }
        catch (error) {
            setFunction(React.createElement("div", { className: 'pt-2 text-danger' }, "That is not a valid wikipedia link."));
            setValidFunction(false);
        }
    });
    const handleCreateGame = (event) => {
        event.preventDefault();
        setPassesValidation(false);
        axios.post('/api/game/createGame', {
            game: 'wikipath',
            player1: person.id,
            player2: player2,
            startLink: startLink,
            endLink: endLink,
            person: person.id
        })
            .then(res => {
            setGames(res.data.games);
            setNewGameCreated(true);
            setActiveGame(res.data.game.id);
        });
    };
    const triggerNewGameForm = () => {
        setPassesValidation(false);
        setIsValidStartLink(false);
        setIsValidEndLink(false);
        setStartLink('');
        setEndLink('');
        setStartMessage('');
        setEndMessage('');
        setPlayer2('');
        setNewGameCreated(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { "data-bs-toggle": "modal", "data-bs-target": "#newGameModal", className: 'btn btn-sm btn-dark w-100 mb-3' },
            React.createElement("span", { className: "material-symbols-outlined ms-1 text-white", style: { 'position': 'relative', 'top': '4px', 'right': '0px', 'fontSize': '18px' } }, "add_circle"),
            " New game"),
        React.createElement(Modal, { title: "New game", icon: "add_circle" }, newGameCreated
            ? React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'd-flex flex-column justify-content-center small' },
                    React.createElement("div", { className: 'text-center text-success mb-2' },
                        React.createElement("span", { className: "material-symbols-outlined ms-1", style: { 'position': 'relative', 'top': '3px', 'right': '2px', 'fontSize': '15px' } }, "check_circle"),
                        "New game created"),
                    React.createElement("div", { className: 'text-center text-primary d-flex justify-content-center' },
                        React.createElement("div", { className: 'me-1', style: { 'cursor': 'pointer' }, "data-bs-dismiss": "modal" }, "Start playing!"),
                        React.createElement("div", { className: 'ms-1', onClick: triggerNewGameForm, style: { 'cursor': 'pointer' } }, "Create another"))))
            : React.createElement("form", { onSubmit: handleCreateGame },
                React.createElement("div", { className: "mb-3 display-block" },
                    React.createElement("label", { htmlFor: "playingAgainst", className: "form-label" }, "Playing against"),
                    React.createElement("select", { value: player2, onChange: (e) => setPlayer2(e.target.value), id: "playingAgainst", className: "form-select display-block", "aria-label": "Default select example" },
                        React.createElement("option", { key: "notSelected", value: "" }),
                        opponents.map((p) => (React.createElement("option", { value: p.id, key: p.id }, p.name))))),
                React.createElement("div", { className: "display-block" },
                    React.createElement("label", { htmlFor: "startingArticle", className: "form-label" }, "Starting article"),
                    React.createElement("input", { id: "startingArticle", className: "form-control", type: "text", placeholder: "Paste the URL", value: startLink, "aria-label": "Starting article", onChange: (e) => {
                            setStartLink(e.target.value);
                            validateLink(e.target.value, setStartMessage, setIsValidStartLink);
                        } })),
                React.createElement("div", { className: 'mb-3' }, startMessage),
                React.createElement("div", { className: "display-block" },
                    React.createElement("label", { htmlFor: "endingArticle", className: "form-label" }, "Ending article"),
                    React.createElement("input", { id: "endingArticle", className: "form-control", type: "text", placeholder: "Paste the URL", value: endLink, "aria-label": "Ending article", onChange: (e) => {
                            setEndLink(e.target.value);
                            validateLink(e.target.value, setEndMessage, setIsValidEndLink);
                        } })),
                React.createElement("div", { className: 'mb-3' }, endMessage),
                React.createElement("button", { type: "submit", className: "btn btn-dark", disabled: !passesValidation }, "Create the game")))));
};
export { NewGame };
