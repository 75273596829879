var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Loading } from 'client/components/Loading';
import { Link } from 'react-router-dom';
const GameRow = ({ game, setActiveGame, activeGame, person }) => {
    const [startTitle, setStartTitle] = useState('...');
    const [endTitle, setEndTitle] = useState('...');
    getTitle(game.wikipath__start_link, setStartTitle);
    getTitle(game.wikipath__end_link, setEndTitle);
    return (React.createElement("li", { className: `list-group-item d-flex flex-column list-group-item-action ${game.id == activeGame ? 'bg-light' : ''}`, key: game.id, style: { 'cursor': 'pointer' }, onClick: () => setActiveGame(game.id) },
        React.createElement(Link, { to: '/games/wikipath/play/' + game.id, style: { 'textDecoration': 'none', 'color': 'black' } },
            (activeGame && activeGame !== game.id)
                ? React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "fw-semibold", style: { 'color': '#888' } },
                        "Game #",
                        game.id),
                    React.createElement("div", null,
                        React.createElement("div", { className: 'small', style: { 'color': '#888', 'lineHeight': '1.2' } },
                            startTitle,
                            " ",
                            React.createElement("span", { className: "material-symbols-outlined", style: { 'fontSize': '18px', 'position': 'relative', 'top': '4px' } }, "conversion_path"),
                            " ",
                            endTitle)))
                : React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "fw-semibold" },
                        "Game #",
                        game.id),
                    React.createElement("div", null,
                        React.createElement("div", { className: 'small', style: { 'lineHeight': '1.2' } },
                            startTitle,
                            " ",
                            React.createElement("span", { className: "material-symbols-outlined", style: { 'fontSize': '18px', 'position': 'relative', 'top': '4px' } }, "conversion_path"),
                            " ",
                            endTitle))),
            React.createElement("div", { className: 'mt-1' },
                React.createElement("span", { className: 'small', style: { 'fontFamily': 'Playfair Display' } }, person.name),
                game.a_game_player.filter(g => g.person == person.id)[0].isDone
                    ? React.createElement("span", { style: {
                            'border': '1px solid black',
                            'backgroundColor': 'rgba(255, 143, 0, 0.1)',
                            'fontSize': '11px',
                            'borderRadius': '4px',
                            'marginLeft': '8px',
                            'padding': '0px 4px',
                            'position': 'relative',
                            'top': '-1px'
                        } },
                        React.createElement("span", { style: { 'borderRight': '1px solid black', 'paddingRight': '4px', 'fontWeight': '600' } }, "Done"),
                        React.createElement("span", { style: { 'paddingLeft': '4px' } },
                            game.a_game_player.filter(g => g.person == person.id)[0].score,
                            " link",
                            game.a_game_player.filter(g => g.person == person.id)[0].score == 1 ? '' : 's'))
                    : React.createElement("span", { className: 'bg-light', style: {
                            'border': '1px solid black',
                            'fontSize': '11px',
                            'borderRadius': '4px',
                            'marginLeft': '8px',
                            'padding': '0px 4px',
                            'position': 'relative',
                            'top': '-1px'
                        } },
                        React.createElement("span", null, "Underway"))),
            React.createElement("div", null,
                React.createElement("span", { className: 'small', style: { 'fontFamily': 'Playfair Display', 'position': 'relative', 'top': '1px' } }, game.a_game_player.filter(g => g.person !== person.id)[0].a_person.name),
                game.a_game_player.filter(g => g.person !== person.id)[0].isDone
                    ? React.createElement("span", { style: {
                            'border': '1px solid black',
                            'backgroundColor': 'rgba(255, 143, 0, 0.1)',
                            'fontSize': '11px',
                            'borderRadius': '4px',
                            'marginLeft': '8px',
                            'padding': '0px 4px',
                            'position': 'relative',
                            'top': '-1px'
                        } },
                        React.createElement("span", { style: { 'borderRight': '1px solid black', 'paddingRight': '4px', 'fontWeight': '600' } }, "Done"),
                        React.createElement("span", { style: { 'paddingLeft': '4px' } },
                            game.a_game_player.filter(g => g.person !== person.id)[0].score,
                            " link",
                            game.a_game_player.filter(g => g.person == person.id)[0].score == 1 ? '' : 's'))
                    : React.createElement("span", { className: 'bg-light', style: {
                            'border': '1px solid black',
                            'fontSize': '11px',
                            'borderRadius': '4px',
                            'marginLeft': '8px',
                            'padding': '0px 4px',
                            'position': 'relative',
                            'top': '-1px'
                        } },
                        React.createElement("span", null, "Underway"))))));
};
const getTitle = (link, setTitle) => __awaiter(void 0, void 0, void 0, function* () {
    if (!link.includes('https://en.wikipedia.org/wiki/')) {
        setTitle('(not found)');
        return;
    }
    try {
        const title = link.split('/').pop();
        const response = yield fetch('https://en.wikipedia.org/api/rest_v1/page/summary/' + title);
        const data = yield response.json();
        if (response.ok) {
            setTitle(data.title);
        }
        else {
            setTitle('(not found)');
        }
    }
    catch (error) {
        setTitle('(not found)');
    }
});
const GamesList = ({ games, activeGame, setActiveGame, person }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", { className: "list-group list-group-flush", style: { 'borderBottomLeftRadius': '0px', 'borderBottom': 'var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)' } },
            games
                ? React.createElement(React.Fragment, null, games.filter(game => game.status == 'underway').sort((a, b) => b.id - a.id).map((game, index, array) => {
                    return (React.createElement(GameRow, { game: game, key: game.id, setActiveGame: setActiveGame, activeGame: activeGame, person: person }));
                }))
                : React.createElement("div", { className: 'py-4' },
                    React.createElement(Loading, null)),
            games && games.filter(game => game.status == 'ended').length > 0 && React.createElement("li", { className: "list-group-item d-flex flex-column py-1 bg-light text-center small", style: { 'color': '#888' } }, "Ended"),
            games
                ? React.createElement(React.Fragment, null, games.filter(game => game.status == 'ended').sort((a, b) => b.id - a.id).map((game, index, array) => {
                    return (React.createElement(GameRow, { game: game, key: game.id, setActiveGame: setActiveGame, activeGame: activeGame, person: person }));
                }))
                : React.createElement("div", { className: 'py-4' }))));
};
export { GamesList };
