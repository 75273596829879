import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { App } from 'client/App';
import { AppLayout } from 'client/AppLayout';
const PrivateRoute = ({ token, children }) => {
    const auth = true; // determine if authorized, from context or however you're doing it
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return token ? children : React.createElement(Navigate, { to: "/" });
};
import { Gate } from 'client/pages/Gate';
import { Games } from 'client/pages/Games';
import { Game } from 'client/pages/Game';
import { NewGame } from 'client/pages/NewGame';
import { PlayGame } from 'client/pages/PlayGame';
import { People } from 'client/pages/People';
const { PUBLIC_URL } = process.env;
function getToken() {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    if (tokenString) {
        return userToken;
    }
    else {
        return null;
    }
}
function getPerson() {
    const nameString = localStorage.getItem('person');
    const person = JSON.parse(nameString);
    return person;
}
const AppRoutes = () => {
    const token = getToken();
    const person = getPerson();
    return (React.createElement(BrowserRouter, { basename: PUBLIC_URL },
        React.createElement(Routes, null,
            React.createElement(Route, { element: React.createElement(App, null) },
                React.createElement(Route, { element: React.createElement(AppLayout, null) },
                    React.createElement(Route, { exact: true, path: '/', element: React.createElement(Gate, null) }),
                    React.createElement(Route, { path: '/games', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Games, { token: token, person: person })) }),
                    React.createElement(Route, { path: '/games/:game', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Game, { token: token, person: person })) }),
                    React.createElement(Route, { path: '/games/:game/play/:id', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Game, { token: token, person: person })) }),
                    React.createElement(Route, { path: '/games/new/:type', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(NewGame, { token: token, person: person })) }),
                    React.createElement(Route, { path: '/games/play/:id', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(PlayGame, { token: token, person: person })) }),
                    React.createElement(Route, { path: '/people', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(People, { token: token, person: person })) }))))));
};
export { AppRoutes };
