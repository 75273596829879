import React from 'react';
import { PersonInitials } from 'client/components/PersonInitials';
const Header = ({ person, title }) => {
    return (React.createElement("nav", { className: 'navbar navbar-expand-lg bg-body-tertiary', style: { 'paddingTop': '24px', 'paddingBottom': '24px' } },
        React.createElement("div", { className: 'container-fluid justify-content-left', style: { 'justifyContent': 'left' } },
            React.createElement("div", { className: "navbar-brand p-0", href: "#", style: { 'position': 'relative', 'border': '1px solid #6E0E0A', 'width': '32px', 'height': '32px', 'marginRight': '24px' } },
                React.createElement("span", { style: { 'position': 'absolute', 'borderLeft': '1px solid black', 'borderRight': '1px solid black', 'height': '5px', 'width': '20px', 'left': '5px' } }),
                React.createElement("span", { style: { 'position': 'absolute', 'borderTop': '1px solid black', 'borderBottom': '1px solid black', 'height': '20px', 'width': '5px', 'top': '5px' } }),
                React.createElement("span", { style: { 'position': 'absolute', 'border': '1px solid black', 'height': '20px', 'width': '20px', 'left': '5px', 'top': '5px', 'backgroundColor': '#ff8f00' } }),
                React.createElement("span", { style: { 'position': 'absolute', 'borderTop': '1px solid black', 'borderBottom': '1px solid black', 'height': '20px', 'width': '5px', 'top': '5px', 'left': '25px' } }),
                React.createElement("span", { style: { 'position': 'absolute', 'borderLeft': '1px solid black', 'borderRight': '1px solid black', 'height': '5px', 'width': '20px', 'left': '5px', 'top': '25px' } }),
                React.createElement("span", { style: { 'position': 'absolute', 'fontFamily': 'Playfair Display', 'fontWeight': '600', 'weight': 'black', 'left': '7.5px', 'top': '-.5px' } }, "G")),
            React.createElement("div", { className: 'd-flex justify-content-left me-auto' },
                React.createElement("span", { style: { 'fontFamily': 'Playfair Display', 'fontSize': '21px' } }, title)),
            React.createElement(PersonInitials, { person: person }))));
};
export { Header };
