var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Loading } from 'client/components/Loading';
import axios from 'axios';
const pullTitle = (link) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const title = link.split('/').pop();
        const response = yield fetch('https://en.wikipedia.org/api/rest_v1/page/summary/' + title);
        const data = yield response.json();
        if (response.ok) {
            return data.title;
        }
    }
    catch (error) {
        return '';
    }
});
const pullLink = (link, linkOut = false, invertImage = false) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const title = link.split('/').pop();
        const response = yield fetch('https://en.wikipedia.org/api/rest_v1/page/summary/' + title);
        const data = yield response.json();
        if (response.ok) {
            let displayTitle = data.title;
            // displayTitle = displayTitle.length > 17 ? `${displayTitle.slice(0, 17)}...` : displayTitle
            return (React.createElement("div", { className: 'd-flex align-items-center' },
                invertImage
                    ? React.createElement(React.Fragment, null,
                        displayTitle,
                        React.createElement("img", { src: data.originalimage.source, height: '30px', className: 'ms-2 rounded' }))
                    : React.createElement(React.Fragment, null,
                        React.createElement("img", { src: data.originalimage.source, height: '30px', className: 'me-2 rounded' }),
                        displayTitle),
                linkOut && React.createElement("a", { style: { 'position': 'relative', 'top': '4px' }, href: "https://en.wikipedia.org/wiki/" + title, target: "_BLANK" },
                    React.createElement("span", { className: "material-symbols-outlined ms-1 text-black" }, "link"))));
        }
        else {
            return React.createElement(React.Fragment, null, "That didn't work");
        }
    }
    catch (error) {
        return React.createElement(React.Fragment, null, "That didn't work");
    }
});
const checkLink = (link) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const title = link.split('/').pop();
        const response = yield fetch('https://en.wikipedia.org/api/rest_v1/page/summary/' + title);
        const data = yield response.json();
        if (response.ok) {
            return true;
        }
        else {
            return false;
        }
    }
    catch (error) {
        return false;
    }
});
const OpponentTurn = ({ turn }) => {
    return (React.createElement("div", { className: ' mb-3' },
        React.createElement("div", { className: 'd-flex align-items-top' },
            React.createElement("style", null, `
            .material-symbols-outlined.spin {
              display: inline-block;
              animation: spin 2s linear infinite;
            }

            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `),
            React.createElement("div", { className: 'pe-2', style: { 'paddingTop': '5px' } },
                React.createElement("div", { className: "d-flex align-items-center justify-content-center", style: { 'marginTop': '4px', 'border': '1px solid black', 'fontSize': '12px', 'width': '20px', 'height': '20px', 'borderRadius': '20px' } },
                    React.createElement("span", { style: { 'position': 'relative', 'top': '1px' } }, turn.number))),
            React.createElement("div", { style: { 'position': 'relative', 'top': '7px' } }, turn.wikipath__link_title))));
};
const Turn = ({ person, turn, turns, setTurns, setOpponentTurns, game, setGames, isDone, setIsDone, setWikiAPIFiring }) => {
    const [linkDisplay, setLinkDisplay] = useState(null);
    const [link, setLink] = useState('');
    const [status, setStatus] = useState('waiting');
    const [isInvalid, setIsInvalid] = useState(false);
    const [score, setScore] = useState(React.createElement(React.Fragment, null));
    useEffect(() => {
        if (turn.status == 'done') {
            setStatus('done');
            setLink(turn.wikipath__link);
            pullLink(turn.wikipath__link, false).then(result => {
                setLinkDisplay(result);
            });
        }
    }, []);
    useEffect(() => {
        if (link !== '' && status == 'waiting') {
            setWikiAPIFiring(true);
            const wikipediaRegex = /^https?:\/\/(en\.)?wikipedia\.org\/wiki\/.+/;
            if (wikipediaRegex.test(link)) {
                setStatus("checking");
                let linkTitle = '';
                pullTitle(link, false).then(result => {
                    linkTitle = result;
                });
                pullLink(link, false).then(result => {
                    setLinkDisplay(result);
                    setStatus('done');
                    if (link == game.wikipath__end_link) {
                        setIsDone(true);
                        axios.post('/api/game/saveTurnDone', {
                            saveTurn: turn.id,
                            saveTurn_status: 'done',
                            saveTurn_wikipath__link: link,
                            saveTurn_wikipath__link_title: linkTitle,
                            game: turn.game,
                            person: turn.person
                        })
                            .then(response => {
                            setGames(response.data.games);
                            setTurns(response.data.turns.filter(g => g.person == person.id));
                            setOpponentTurns(response.data.turns.filter(g => g.person !== person.id));
                            let newScores = response.data.a_game_player.map((player) => {
                                return (React.createElement("div", null,
                                    player.a_person.name,
                                    ": ",
                                    player.isDone ? player.score : 'Still playing'));
                            });
                            setScore(newScores);
                            setWikiAPIFiring(false);
                        })
                            .catch(error => {
                            console.error('There was a problem with the fetch operation:', error);
                        });
                    }
                    else {
                        let currentTurn = turns.reduce((max, obj) => obj.number > max.number ? obj : max, turns[0]);
                        let newTurns = turns;
                        newTurns = newTurns.map(obj => {
                            if (obj.id === currentTurn.id) {
                                return Object.assign(Object.assign({}, obj), { status: 'done' });
                            }
                            return obj;
                        });
                        axios.post('/api/game/saveTurnCreateTurn', {
                            saveTurn: turn.id,
                            saveTurn_status: 'done',
                            saveTurn_wikipath__link: link,
                            saveTurn_wikipath__link_title: linkTitle,
                            game: turn.game,
                            person: turn.person,
                            wikipath__link: null,
                            status: 'underway'
                        })
                            .then(response => {
                            setTurns(response.data);
                            setWikiAPIFiring(false);
                        })
                            .catch(error => {
                            console.error('There was a problem with the fetch operation:', error);
                        });
                    }
                });
            }
            else {
                console.log('The link is not a valid Wikipedia URL.');
                setIsInvalid(true);
            }
        }
    }, [link]);
    return (React.createElement("div", { className: ' mb-3' },
        React.createElement("div", { className: 'd-flex align-items-top' },
            React.createElement("style", null, `
            .material-symbols-outlined.spin {
              display: inline-block;
              animation: spin 2s linear infinite;
            }

            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `),
            React.createElement("div", { className: 'pe-2', style: { 'paddingTop': '5px' } },
                React.createElement("div", { className: "d-flex align-items-center justify-content-center", style: { 'marginTop': '4px', 'border': '1px solid black', 'fontSize': '12px', 'width': '20px', 'height': '20px', 'borderRadius': '20px' } },
                    React.createElement("span", { style: { 'position': 'relative', 'top': '1px' } }, turn.number))),
            (status == 'done' && turn.wikipath__link_title !== null && turn.wikipath__link_title !== '')
                ? React.createElement("div", { style: { 'position': 'relative', 'top': '7px' } }, turn.wikipath__link_title)
                : React.createElement(React.Fragment, null, status == 'done'
                    ? React.createElement("div", { style: { 'position': 'relative', 'top': '6px', 'left': '5px' } },
                        React.createElement(Loading, null))
                    : React.createElement("input", { value: link, onChange: (e) => setLink(e.target.value), className: 'form-control', type: "text", placeholder: "", "aria-label": "Disabled input example" }))),
        isInvalid && React.createElement("div", { className: 'pt-2 text-danger', style: { 'marginLeft': '29px' } }, "That is not a valid wikipedia link.")));
};
const PlayGame = ({ person, game, setGames, isLoaded, setIsLoaded }) => {
    const [articlesReady, setArticlesReady] = useState(false);
    const [startLinkDisplay, setStartLinkDisplay] = useState(null);
    const [startLinkRowDisplay, setStartLinkRowDisplay] = useState(null);
    const [endLinkDisplay, setEndLinkDisplay] = useState(null);
    const [turns, setTurns] = useState(null);
    const [opponentTurns, setOpponentTurns] = useState(null);
    const [turnsDisplay, setTurnsDisplay] = useState(null);
    const [isDone, setIsDone] = useState(false);
    const [activeLink, setActiveLink] = useState(null);
    const [wikiAPIFiring, setWikiAPIFiring] = useState(false);
    useEffect(() => {
        if (game) {
            setWikiAPIFiring(true);
            pullLink(game.wikipath__start_link, false).then(result => {
                setStartLinkDisplay(result);
                pullLink(game.wikipath__start_link, false).then(resultRow => {
                    setStartLinkRowDisplay(resultRow);
                    pullLink(game.wikipath__end_link, false, false).then(resultEnd => {
                        setEndLinkDisplay(resultEnd);
                        setArticlesReady(true);
                        setIsLoaded(prevState => (Object.assign(Object.assign({}, prevState), { articlesReady: true })));
                        setWikiAPIFiring(false);
                    });
                });
            });
            if (game.a_game_player.filter(g => g.person == person.id)[0].isDone === true) {
                setIsDone(true);
            }
        }
    }, [game]);
    useEffect(() => {
        axios.post('/api/game/fetchTurns', {
            game: game.id,
            person: person.id
        })
            .then(response => {
            setTurns(response.data.filter(g => g.person == person.id));
            setOpponentTurns(response.data.filter(g => g.person !== person.id));
            // setIsLoaded(prevState => ({ ...prevState, fetchTurns: true }))
        })
            .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }, []);
    useEffect(() => {
        if (turns !== null) {
            if (turns.length > 0) {
                const newTurnsDisplay = turns.sort((a, b) => a.number - b.number).map((turn, i) => {
                    return (React.createElement(Turn, { setWikiAPIFiring: setWikiAPIFiring, person: person, key: i, setGames: setGames, setOpponentTurns: setOpponentTurns, turn: turn, turns: turns, setTurns: setTurns, game: game, isDone: isDone, setIsDone: setIsDone }));
                });
                setTurnsDisplay(newTurnsDisplay);
                if (turns.length == 1) {
                    setActiveLink(game.wikipath__start_link);
                }
                if (turns.length > 1) {
                    setActiveLink(turns.sort((a, b) => a.number - b.number)[turns.length - 2].wikipath__link);
                }
            }
            else {
                axios.post('/api/game/createFirstTurn', {
                    game: game.id,
                    person: person.id,
                    wikipath__link: null,
                    status: 'underway'
                })
                    .then(response => {
                    setTurns(response.data.filter(g => g.person == person.id));
                    setOpponentTurns(response.data.filter(g => g.person !== person.id));
                });
            }
        }
    }, [turns]);
    return (React.createElement("div", { className: 'flex-grow-1 d-flex flex-column' },
        articlesReady ?
            (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "d-flex p-3 border-bottom justify-content-center align-items-center", style: { 'minHeight': '81px' } },
                    React.createElement("div", { className: 'd-flex justify-content-end', style: { 'width': 'calc(50% - 13px)' } }, startLinkDisplay),
                    React.createElement("div", { className: 'mx-4 d-flex align-items-center', style: { 'position': 'relative', 'top': '30x' } },
                        React.createElement("div", { style: { 'backgroundColor': 'rgb(255, 143, 0)', 'padding': '4px', 'borderRadius': '40px', height: '26px' } },
                            React.createElement("span", { className: "material-symbols-outlined", style: { 'fontSize': '18px' } }, "conversion_path"))),
                    React.createElement("div", { style: { 'width': 'calc(50% - 13px)' } }, endLinkDisplay)),
                React.createElement("div", { className: "d-flex border-bottom justify-content-center" },
                    React.createElement("div", { className: 'w-50 text-center border-end p-1' }, person.name),
                    React.createElement("div", { className: 'w-50 text-center p-1' }, game.a_game_player.filter(g => g.person !== person.id)[0].a_person.name))))
            :
                (React.createElement("div", { style: { 'height': '81px' }, className: 'd-flex align-items-center justify-content-center border-bottom' },
                    React.createElement(Loading, null))),
        game && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'card-body p-0 flex-grow-1 d-flex' },
                React.createElement("div", { className: 'w-50 border-end p-3 h-100' },
                    turnsDisplay &&
                        React.createElement(React.Fragment, null, turnsDisplay),
                    isDone && !wikiAPIFiring &&
                        React.createElement("div", { className: 'd-flex justify-content-center mt-4' },
                            React.createElement("span", { className: "material-symbols-outlined", style: { 'color': 'rgb(255, 143, 0)' } }, "celebration"),
                            React.createElement("h5", { className: 'px-2', style: { 'color': 'rgb(255, 143, 0)' } }, "You made it"),
                            React.createElement("span", { className: "material-symbols-outlined", style: { 'color': 'rgb(255, 143, 0)' } }, "celebration"))),
                React.createElement("div", { className: 'w-50 border-end p-3 h-100' }, opponentTurns && opponentTurns.length > 0 && opponentTurns[opponentTurns.length - 1].status == 'done'
                    ? React.createElement(React.Fragment, null, turns[turns.length - 1].status == 'done'
                        ? React.createElement(React.Fragment, null,
                            opponentTurns.map((turn, i) => {
                                return (React.createElement(OpponentTurn, { turn: turn, key: i }));
                            }),
                            React.createElement("div", { className: 'd-flex justify-content-center mt-4' },
                                React.createElement("h5", { className: 'px-2 fw-normal' },
                                    game.a_game_player.filter(g => g.person !== person.id)[0].a_person.name,
                                    " made it too")))
                        : React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'd-flex justify-content-center mt-4' },
                                React.createElement("h5", { className: 'px-2 fw-normal' },
                                    game.a_game_player.filter(g => g.person !== person.id)[0].a_person.name,
                                    "  made it"))))
                    : React.createElement(React.Fragment, null, opponentTurns && opponentTurns.length > 1
                        ? React.createElement("div", null)
                        : React.createElement("div", { className: 'p-3 d-flex justify-content-center text-muted' },
                            React.createElement("div", { className: 'text-center w-100 d-flex justify-content-center', style: { 'marginTop': '8px' } },
                                game.a_game_player.filter(g => g.person !== person.id)[0].a_person.name,
                                "  is working on it."))))),
            (activeLink && !isDone) &&
                React.createElement("div", { className: 'border-top p-2 d-flex justify-content-center', style: { 'borderBottomRightRadius': '4px', 'height': '57px' } },
                    React.createElement("div", null,
                        React.createElement("a", { className: 'btn btn-sm btn-dark', style: { 'position': 'relative', 'top': '4px', 'paddingRight': '27px' }, href: "https://en.wikipedia.org/wiki/" + activeLink.split('/').pop(), target: "_BLANK" },
                            "Your current link ",
                            React.createElement("span", { style: { 'position': 'absolute', 'color': 'white', 'fontSize': '20px' }, className: "material-symbols-outlined ms-1" }, "arrow_forward"))))))));
};
export { PlayGame };
