import React from 'react';
const Footer = ({ person }) => {
    return (React.createElement("div", { className: 'container-fluid mt-auto d-flex', style: { 'paddingBottom': '24px' } },
        React.createElement("style", null, `
          .release-corner:hover .release-information {
            right: 32px !important;
            visibility: visible !important;
            padding-right: 8px !important
          }
        `),
        React.createElement("div", { className: "navbar-brand p-0", href: "#", style: { 'position': 'relative', 'border': '1px solid #6E0E0A', 'width': '32px', 'height': '32px' } },
            React.createElement("span", { style: { 'position': 'absolute', 'borderLeft': '1px solid black', 'borderRight': '1px solid black', 'height': '5px', 'width': '20px', 'left': '5px' } }),
            React.createElement("span", { style: { 'position': 'absolute', 'borderTop': '1px solid black', 'borderBottom': '1px solid black', 'height': '20px', 'width': '5px', 'top': '5px' } }),
            React.createElement("span", { style: { 'position': 'absolute', 'borderTop': '1px solid black', 'borderBottom': '1px solid black', 'height': '20px', 'width': '5px', 'top': '5px', 'left': '25px' } }),
            React.createElement("span", { style: { 'position': 'absolute', 'borderLeft': '1px solid black', 'borderRight': '1px solid black', 'height': '5px', 'width': '20px', 'left': '5px', 'top': '25px' } }),
            React.createElement("span", { style: { 'position': 'absolute', 'fontFamily': 'Playfair Display', 'fontWeight': '600', 'weight': 'black', 'left': '11px', 'top': '3px' } }, "\u263F")),
        React.createElement("div", { className: "navbar-brand p-0 ms-auto release-corner", href: "#", style: { 'backgroundColor': 'white', 'zIndex': '20', 'position': 'relative', 'border': '1px solid #6E0E0A', 'width': '32px', 'height': '32px' } },
            React.createElement("span", { style: { 'zIndex': '20', 'position': 'absolute', 'border': '1px solid black', 'height': '20px', 'width': '20px', 'left': '5px', 'top': '5px', 'backgroundColor': '#ffffff' } }),
            React.createElement("span", { style: { 'zIndex': '20', 'position': 'absolute', 'fontFamily': 'Playfair Display', 'fontWeight': '600', 'weight': 'black', 'left': '10px', 'top': '2px' } }, "\u03B1"),
            React.createElement("div", { style: { 'position': 'absolute', 'width': '30px', 'height': '30px', 'backgroundColor': 'white', 'zIndex': '10' } }),
            React.createElement("div", { className: 'release-information small', style: { 'zIndex': '1', 'visibility': 'hidden', 'transition': 'all 0.3s ease', 'position': 'absolute', 'right': '0px', 'top': '0px', 'lineHeight': '16px', 'textAlign': 'right' } },
                React.createElement("div", null,
                    "Release ",
                    React.createElement("span", { style: { 'fontFamily': 'Playfair Display', 'fontWeight': '600' } }, "\u03B1.1.1")),
                React.createElement("div", { style: { 'fontSize': '11px', 'color': '#6c757d' } }, "Text me if you encounter bugs")))));
};
export { Footer };
