import React from 'react';
const GameHeader = () => {
    return (React.createElement("div", { className: 'card d-block border-dark wikipath-card mb-3', style: { 'borderRadius': '4px' } },
        React.createElement("div", { className: 'card-body py-5 d-flex flex-column' },
            React.createElement("h5", { className: 'card-title mb-1', style: { 'position': 'relative' } },
                React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'absolute', 'left': '-3px' } }, "conversion_path"),
                React.createElement("span", { style: { 'paddingLeft': '27px' } }, "Wikipath")),
            React.createElement("p", { className: "card-subtitle mb-0 text-muted", style: { 'position': 'relative', 'top': '5px', 'left': '0px' } }, "Short circuit the world's knowledge."))));
};
export { GameHeader };
