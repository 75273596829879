import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'client/components/Header';
import { Footer } from 'client/components/Footer';
import { LeftSidebar } from 'client/components/LeftSidebar';
import { RightSidebar } from 'client/components/RightSidebar';
import { Modal } from 'client/components/Modal';
import axios from 'axios';
const Games = ({ token, person }) => {
    const [players, setPlayers] = useState([]);
    const [proposal, setProposal] = useState(null);
    const [proposalSubmitted, setProposalSubmitted] = useState(false);
    const [passesValidation, setPassesValidation] = useState(true);
    useEffect(() => {
        axios.post('/api/game/fetchPlayers', {})
            .then(response => {
            setPlayers(response.data);
        })
            .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }, []);
    const handleProposeGame = (event) => {
        event.preventDefault();
        setPassesValidation(false);
        axios.post('/api/game/proposeGame', {
            proposal: proposal,
            proposer: person.id
        })
            .then(res => {
            setProposalSubmitted(true);
        });
    };
    return (React.createElement("div", { className: 'container-fluid flex-none d-flex flex-column min-vh-100' },
        React.createElement(Header, { person: person, title: "Games" }),
        React.createElement("div", { className: 'container-fluid flex-grow-1 d-flex' },
            React.createElement(LeftSidebar, null),
            React.createElement("div", { className: 'container-fluid' },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-lg-4' },
                        React.createElement("div", { className: 'card d-block border-dark wikipath-card mb-3', style: { 'borderRadius': '4px' } },
                            React.createElement("div", { className: 'card-body py-4' },
                                React.createElement("h5", { className: 'card-title', style: { 'position': 'relative' } },
                                    React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'absolute', 'left': '-3px' } }, "conversion_path"),
                                    React.createElement("span", { style: { 'paddingLeft': '27px' } }, "Wikipath")),
                                React.createElement("p", { className: "card-subtitle mb-2 text-muted" }, "Short circuit the world's knowledge."),
                                React.createElement(Link, { to: "/games/wikipath", className: "btn btn-dark btn-sm" }, "Play")))),
                    React.createElement("div", { className: 'col-lg-4' },
                        React.createElement("div", { style: { 'height': '141px', 'borderRadius': '4px' }, className: 'card d-block border-muted mb-3' },
                            React.createElement("div", { className: 'card-body py-4' },
                                React.createElement("h5", { className: 'card-title text-muted fw-light' },
                                    React.createElement("span", { style: { 'position': 'absolute', 'fontWeight': '300' }, className: 'material-symbols-outlined spinSlow pe-1 thin' }, "settings"),
                                    React.createElement("span", { style: { 'position': 'relative', 'left': '30px' } }, "Contemplating")),
                                React.createElement("p", { className: "card-subtitle mb-2 text-muted" }, "\u00A0"))))),
                React.createElement("div", null,
                    React.createElement("div", { className: 'col-lg-5' },
                        React.createElement("p", { className: 'mb-3 mt-4' }, "I am looking for my next coding project and would like to do something for our cohort community. If you have an idea of some tech that would be useful or fun let me know!"),
                        React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                React.createElement("button", { "data-bs-toggle": "modal", "data-bs-target": "#newGameModal", className: 'btn btn-sm btn-dark' }, "Share an idea")),
                            React.createElement(Modal, { title: "Share an idea", icon: "lightbulb" }, proposalSubmitted
                                ? React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: 'd-flex flex-column justify-content-center small' },
                                        React.createElement("div", { className: 'text-center text-success mb-2' },
                                            React.createElement("span", { className: "material-symbols-outlined ms-1", style: { 'position': 'relative', 'top': '3px', 'right': '2px', 'fontSize': '15px' } }, "check_circle"),
                                            "Received! Thank you!"),
                                        React.createElement("div", { className: 'text-center text-primary d-flex justify-content-center' },
                                            React.createElement("div", { onClick: () => { setProposalSubmitted(false); setProposal(null); }, className: 'me-1', style: { 'cursor': 'pointer' }, "data-bs-dismiss": "modal" }, "Back to games"))))
                                : React.createElement("form", { onSubmit: handleProposeGame },
                                    React.createElement("div", { className: "mb-3 display-block" },
                                        React.createElement("div", { className: "display-block" },
                                            React.createElement("textarea", { id: "startingArticle", rows: '8', className: "form-control", type: "text", placeholder: "Write as much or as little", value: proposal, "aria-label": "Starting article", onChange: (e) => {
                                                    setProposal(e.target.value);
                                                } }))),
                                    React.createElement("button", { type: "submit", className: "btn btn-dark btn-sm", disabled: !passesValidation }, "Submit"))))))),
            React.createElement(RightSidebar, { players: players })),
        React.createElement(Footer, null)));
};
export { Games };
