import React from 'react';
const Modal = ({ title, icon, children }) => {
    return (React.createElement("div", { id: 'newGameModal', "aria-hidden": "true", className: "modal fade", tabIndex: "-1" },
        React.createElement("div", { className: "modal-dialog" },
            React.createElement("div", { className: "modal-content", style: { 'borderRadius': '4px' } },
                React.createElement("div", { className: 'modal-header d-flex justify-content-between border-dark', style: { 'backgroundColor': 'rgba(255, 143, 0, 0.1)', 'padding': '3px 1px 1px 11px' } },
                    React.createElement("h6", { className: 'modal-title small', style: { 'marginBottom': '4px' } },
                        React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'relative', 'top': '4px', 'right': '1px', 'fontSize': '18px' } }, icon),
                        title),
                    React.createElement("button", { type: "button", className: "btn btn-link", "data-bs-dismiss": "modal", "aria-label": "Close" },
                        React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'absolute', 'top': '8px', 'right': '11px', 'fontSize': '18px' } }, "close"))),
                React.createElement("div", { className: "modal-body" }, children)))));
};
export { Modal };
