import React, { useEffect, useState } from 'react';
import { Loading } from 'client/components/Loading';
import { PersonInitials } from 'client/components/PersonInitials';
const countStats = (person, games) => {
    let count = 0;
    let scores = [];
    const personGames = games.forEach(game => {
        game.a_game_player.forEach(player => {
            if (player.a_person.id === person.id) {
                count++;
                scores.push(player.score);
            }
        });
    });
    let meanScore = '';
    const filteredScores = scores.filter(score => score !== null);
    if (filteredScores.length > 0) {
        const sum = filteredScores.reduce((acc, num) => acc + num, 0);
        meanScore = Math.round(sum / filteredScores.length * 100) / 100;
    }
    else {
        meanScore = 'N/A';
    }
    const stats = {
        n: count,
        meanScore: meanScore
    };
    return stats;
};
const Leaderboard = ({ players, games }) => {
    const [localPlayers, setLocalPlayers] = useState(null);
    useEffect(() => {
        console.log(games);
        if (games !== null && games !== undefined) {
            let newLocalPlayers = localPlayers;
            players.forEach(player => {
                const stats = countStats(player, games);
                // console.log('*** stats')
                // console.log(player.id)
                // console.log(stats)
                newLocalPlayers = newLocalPlayers.map(p => p.id === player.id ? Object.assign(Object.assign({}, p), { n: stats.n, meanScore: stats.meanScore }) : p);
            });
            newLocalPlayers = newLocalPlayers.filter(obj => obj.n !== 0);
            newLocalPlayers = newLocalPlayers.sort((a, b) => a.meanScore - b.meanScore);
            let rank = 1;
            newLocalPlayers = newLocalPlayers.map((p) => {
                p.rank = rank;
                rank = rank + 1;
                return p;
            });
            setLocalPlayers(newLocalPlayers);
        }
    }, [games]);
    useEffect(() => {
        setLocalPlayers(players);
    }, [players]);
    return (React.createElement("div", { className: 'card d-block border-dark mb-3', style: { 'borderRadius': '4px' } },
        React.createElement("div", { className: 'card-header d-flex justify-content-left border-dark', style: { 'padding': '1px', 'backgroundColor': 'rgba(255, 143, 0, 0.1)', 'paddingLeft': '16px' } },
            React.createElement("h6", { className: 'card-title small', style: { 'marginBottom': '4px' } },
                React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { 'position': 'relative', 'top': '4px', 'right': '7px', 'fontSize': '18px' } }, "trophy"),
                "Leaderboard")),
        React.createElement("ul", { className: "list-group list-group-flush" }, localPlayers
            ? React.createElement(React.Fragment, null, localPlayers.map((player, index, array) => {
                return (React.createElement("li", { className: "list-group-item d-flex", key: index },
                    React.createElement("div", { className: 'd-flex' },
                        React.createElement("span", { style: { 'paddingTop': '5px', 'paddingRight': '10px' } },
                            player.rank,
                            "."),
                        React.createElement(PersonInitials, { person: player }),
                        React.createElement("span", { style: { 'fontFamily': 'Playfair Display', 'marginLeft': '8px', 'marginTop': '3px' } },
                            player.name,
                            index == 0 && React.createElement("span", { className: "material-symbols-outlined ms-1", style: { 'color': 'rgb(255, 143, 0)', 'position': 'relative', 'top': '4px', 'right': '1px', 'fontSize': '18px' } }, "military_tech"))),
                    React.createElement("div", { className: 'ms-auto d-flex align-items-center' },
                        React.createElement("span", { className: 'text-muted me-3 small' },
                            "n=",
                            player.n),
                        React.createElement("span", { className: 'text-muted small' },
                            "\u03BC=",
                            player.meanScore))));
            }))
            : React.createElement(Loading, null))));
};
export { Leaderboard };
