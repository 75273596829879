import React, { useEffect, useState } from 'react';
import { Header } from 'client/components/Header';
import { Footer } from 'client/components/Footer';
import { LeftSidebar } from 'client/components/LeftSidebar';
import { RightSidebar } from 'client/components/RightSidebar';
import { PersonInitials } from 'client/components/PersonInitials';
import axios from 'axios';
const People = ({ token, person, games }) => {
    const [players, setPlayers] = useState([]);
    const [newPerson, setNewPerson] = useState(null);
    const [personSubmitted, setPersonSubmitted] = useState(false);
    const [passesValidation, setPassesValidation] = useState(false);
    const [newPersonRecord, setNewPersonRecord] = useState(null);
    useEffect(() => {
        if (newPerson && newPerson.trim().length !== 0) {
            setPassesValidation(true);
        }
        else {
            setPassesValidation(false);
        }
    }, [newPerson]);
    useEffect(() => {
        axios.post('/api/game/fetchPlayers', {})
            .then(response => {
            setPlayers(response.data);
        })
            .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }, []);
    const handleAddPerson = (event) => {
        event.preventDefault();
        setPassesValidation(false);
        axios.post('/api/people/addPerson', {
            name: newPerson,
            referrer: person.id
        })
            .then(res => {
            setPersonSubmitted(true);
            setNewPersonRecord(res.data.person);
            setPlayers(res.data.people);
        });
    };
    return (React.createElement("div", { className: 'container-fluid flex-none d-flex flex-column min-vh-100' },
        React.createElement(Header, { person: person, title: "People" }),
        React.createElement("div", { className: 'container-fluid flex-grow-1 d-flex' },
            React.createElement(LeftSidebar, null),
            React.createElement("div", { className: 'container-fluid' },
                React.createElement("div", { className: 'mb-3' }, "Let me know if you want to add a person!"),
                React.createElement("div", { className: 'row' }, players.map((player) => {
                    return (React.createElement("div", { className: 'col-lg-3' },
                        React.createElement("div", { className: 'card d-block border-dark mb-3', style: { 'borderRadius': '4px' } },
                            React.createElement("div", { className: 'card-body py-4 px-4' },
                                React.createElement(PersonInitials, { person: player }),
                                React.createElement("div", { className: 'mt-4 d-flex justify-content-end' },
                                    React.createElement("h4", { className: 'fw-normal mb-0', style: { 'fontFamily': 'Playfair Display' } }, player.name))))));
                }))),
            React.createElement(RightSidebar, { players: players })),
        React.createElement(Footer, null)));
};
export { People };
