var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
function authenticate(credentials) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch('/api/authentication/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    });
}
function createSession(userInformation) {
    localStorage.setItem('token', JSON.stringify(userInformation.token));
    localStorage.setItem('person', JSON.stringify(userInformation.person));
}
const Gate = () => {
    const [keycode, setKeycode] = useState();
    const [authenticationStatus, setAuthenticationStatus] = useState();
    const [localToken, setLocalToken] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setAuthenticationStatus('Checking...');
        const userInformation = yield authenticate({
            keycode
        });
        if (userInformation.token === null) {
            setAuthenticationStatus('Not authenticated.');
        }
        else {
            setAuthenticationStatus(React.createElement("div", null,
                "Welcome ",
                React.createElement("span", { style: { 'fontFamily': 'Playfair Display' } }, userInformation.person.initials),
                ". ",
                React.createElement("a", { className: 'text-decoration-none', href: '/games' }, "Proceed \u2192")));
            setIsAuthenticated(true);
            createSession(userInformation);
        }
    });
    return (React.createElement("div", { className: 'container px-4', style: { 'paddingTop': '33px' } },
        React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'mb-3' },
                React.createElement("p", null, "Welcome! What is your passkey?"),
                isAuthenticated !== true
                    ?
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("input", { id: 'keycode', style: { 'width': '222px' }, className: 'form-control me-2', onChange: e => setKeycode(e.target.value) }),
                            React.createElement("button", { type: "submit", className: "btn btn-dark", onClick: handleSubmit }, "Try"))
                    :
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("input", { id: 'keycode', style: { 'width': '222px', 'backgroundColor': '#ff8f00', 'border': '1px solid black' }, className: 'form-control me-2', value: keycode }))),
            React.createElement("div", null, authenticationStatus))));
};
export { Gate };
