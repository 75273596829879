import React, { useState } from 'react';
const HowItWorks = () => {
    // State to track visibility of the card body
    const [isBodyVisible, setIsBodyVisible] = useState(false);
    // Toggle function to change visibility
    const toggleBodyVisibility = () => {
        setIsBodyVisible(!isBodyVisible);
    };
    return (React.createElement("div", { className: 'card d-block border-dark mb-3', style: { borderRadius: '4px' } },
        React.createElement("div", { className: `card-header d-flex justify-content-between align-items-center border-dark ${isBodyVisible ? '' : 'border-0'}`, style: { padding: '1px', backgroundColor: 'rgba(255, 143, 0, 0.1)', paddingLeft: '16px' } },
            React.createElement("h6", { className: 'card-title small', style: { marginBottom: '4px' } },
                React.createElement("span", { className: "material-symbols-outlined ms-1 text-black", style: { position: 'relative', top: '4px', right: '9px', fontSize: '18px' } }, "help"),
                "How it works"),
            React.createElement("span", { className: "material-symbols-outlined ms-1", style: { cursor: 'pointer', fontSize: '24px', 'paddingRight': '5px', 'paddingTop': '1px' }, onClick: toggleBodyVisibility }, isBodyVisible ? 'expand_less' : 'expand_more')),
        isBodyVisible && (React.createElement("div", { className: 'card-body', style: { padding: '8px 16px' } },
            React.createElement("ul", { style: { 'paddingLeft': '13px' }, className: 'mb-0' },
                React.createElement("li", { className: 'mb-2' }, "To start a new game, pick two wikipedia articles \u2014 the less related, the better."),
                React.createElement("li", { className: 'mb-2' }, "The goal is to get from one to the other by clicking internal links."),
                React.createElement("li", { className: 'mb-2' }, "Once you've finished, you can see your opponents path as well."))))));
};
export { HowItWorks };
