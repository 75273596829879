import React from 'react';
import { createRoot } from 'react-dom/client';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import './client/App.css';
import './client/assets/sass/style.scss';
import './client/assets/sass/style.react.scss';
import { AppRoutes } from 'client/AppRoutes';
import 'material-symbols';
Chart.register(...registerables);
const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(React.createElement(QueryClientProvider, { client: queryClient },
    React.createElement(AppRoutes, null)));
// reportWebVitals(console.log);
